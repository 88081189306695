.container {
  display: grid;
  grid-template-columns: 1fr 2fr;

  .logo_mobile {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: block;
    height: auto;

    .logo_mobile {
      width: 20rem;
      position: relative;
      display: inline-block;
      margin-bottom: 17rem;
      padding: 0;
    }
  }

  .welcome {
    padding: 4rem 3rem 4rem 4rem;
    background-color: var(--color-green);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    color: white;

    @media screen and (max-width: 768px) {
      display: none;
    }

    .logo {
      width: 25rem;
      margin-bottom: 2.5rem;
    }

    .b_prof {
      letter-spacing: 0.58px;
      font-weight: 500;
      font-size: 1.92rem;
    }

    div {
      height: 4px;
      width: 100%;
      background-color: #e4e4e4;
      border-radius: 100px;
      margin-top: 1.28rem;
      margin-bottom: 0.8rem;

      div {
        background-color: var(--color-primary);
        border-radius: 100px;
        height: 100%;
      }
    }

    .status {
      text-align: right;
      letter-spacing: 0.38px;
      font-size: 1.28rem;
      margin-bottom: 5rem;
    }

    .thanks {
      margin-top: 3rem;
      letter-spacing: 0.43px;
      font-size: 1.44rem;
      margin-bottom: 3.2rem;
    }

    h3 {
      letter-spacing: 1.03px;
      font-size: 3.2rem;
      font-weight: 500;
      margin-bottom: 3.2rem;
    }

    h6 {
      font-weight: normal;
      font-size: 1.92rem;
      letter-spacing: 0.58px;
    }
  }

  .main_content {
    display: flex;

    .options {
      width: max-content;
      background-color: #f7f7f7;
      padding: 17rem 0;
      color: #707070;
      font-size: 1.6rem;
      position: relative;

      @media screen and (max-width: 768px) {
        padding: 0;
        position: sticky;
        top: 0;
        display: none;
        align-self: flex-start;
      }

      .check {
        width: 3.2rem;
        height: 3.2rem;
        background-color: #707070;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
          width: 2.5rem;
          height: 2.5rem;
        }


        svg {
          fill: white;
          height: 1.12rem;
          width: 1.44rem;
        }
      }

      .card {
        display: flex;
        column-gap: 1.92rem;
        align-items: center;
        padding: 1rem 3.8rem;
        margin: 0.92rem 0;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        @media screen and (max-width: 768px) {
          padding: 1rem;
          column-gap: 1rem;
        }

      }

      .card_active {
        background-color: var(--color-primary);
        color: #000;
        font-weight: 500;
        font-size: 1.92rem;
        position: relative;
        column-gap: 1rem;

        @media screen and (max-width: 768px) {
          font-size: 1.7rem;
          column-gap: 1rem;
        }


        .check {
          position: relative;
          left: -0.5rem;
          width: 4.4rem;
          height: 4.4rem;
          background-color: #fff;

          @media screen and (max-width: 768px) {
            display: grid;
            place-items: center;
            width: 2.7rem;
            height: 2.7rem;
            left: 0;
          }

        }

        p {
          position: relative;
        }
      }
    }

    .content {
      flex: 1;
      background-color: #fff;
      padding-top: 3rem;
      height: 100vh;

      @media screen and (max-width: 768px) {
        height: auto;
        //overflow-y: scroll;
      }


      h1 {
        font-size: 3.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
        padding: 0 2rem;
      }


      button {
        margin-top: 2.2rem;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        border-radius: 10px;
        color: white;
        border: none;
        padding: 2.24rem 0;
        outline: none;
        background-color: var(--color-green);
        font-weight: 500;
        font-size: 1.72rem;
        display: flex;
        column-gap: 1.28rem;
        justify-content: center;
        width: 60%;

        &:disabled {
          background-color: #707070;
        }

        @media screen and (max-width: 768px) {
          margin-bottom: 2rem;
        }

        svg {
          fill: white;
        }
      }

      p {
        margin-left: auto;
        margin-right: auto;
        text-transform: uppercase;
        text-decoration: underline;
        letter-spacing: 0.43px;
        font-weight: 500;
        font-size: 1.44rem;
        text-align: center;
        margin-top: 2.2rem;
        cursor: pointer;
      }
    }

    .inputs {
      overflow-y: auto;
      height: 65vh;

      @media screen and (max-width: 768px) {
        height: auto;
        //overflow-y: scroll;
      }

      &::-webkit-scrollbar {
        width: 3px;
        height: 1px;
      }

      &::-webkit-scrollbar-track {
        background-color: #707070;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary);
        border-radius: 10px;
      }
    }
  }
}
