/**
  * Dots flow
  *
  * @author jh3y - jheytompkins.com
*/

.container {
    height: 18px;
    display: flex;
    overflow: hidden;
    width: 100%;
    position: relative;
}

.dots-flow:before {
    -webkit-animation: dots-flow .85s infinite ease;
    animation: dots-flow .85s infinite ease;
    border-radius: 100%;
    content: '';
    height: 5px;
    position: absolute;
    top: 70%;
    left: 50%;
    -webkit-transform: translate(-40%, -40px);
    transform: translate(-40%, -40px);
    width: 5px;
}

@-webkit-keyframes dots-flow {
    0%, 100% {
        -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
    }
    35% {
        -webkit-box-shadow: -26px 32px 0 4px white, 0px 32px 0 0 white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0 4px white, 0px 32px 0 0 white, 26px 32px 0 0 white;
    }
    50% {
        -webkit-box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px white, 26px 32px 0 0 white;
    }
    65% {
        -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px white;
        box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px white;
    }
}

@keyframes dots-flow {
    0%, 100% {
        -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
    }
    35% {
        -webkit-box-shadow: -26px 32px 0 4px white, 0px 32px 0 0 white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0 4px white, 0px 32px 0 0 white, 26px 32px 0 0 white;
    }
    50% {
        -webkit-box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px white, 26px 32px 0 0 white;
    }
    65% {
        -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px white;
        box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px white;
    }
}


@-webkit-keyframes whirl {
    0% {
        -webkit-transform: scale(0) rotate(-45deg) translateY(0);
        transform: scale(0) rotate(-45deg) translateY(0);
    }
    50% {
        -webkit-transform: scale(1.25) rotate(960deg) translateY(100%);
        transform: scale(1.25) rotate(960deg) translateY(100%);
    }
    100% {
        -webkit-transform: scale(0) rotate(2000deg) translateY(0);
        transform: scale(0) rotate(2000deg) translateY(0);
    }
}

@keyframes whirl {
    0% {
        -webkit-transform: scale(0) rotate(-45deg) translateY(0);
        transform: scale(0) rotate(-45deg) translateY(0);
    }
    50% {
        -webkit-transform: scale(1.25) rotate(960deg) translateY(100%);
        transform: scale(1.25) rotate(960deg) translateY(100%);
    }
    100% {
        -webkit-transform: scale(0) rotate(2000deg) translateY(0);
        transform: scale(0) rotate(2000deg) translateY(0);
    }
}

.whirl_container {
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 175px;
    overflow: hidden;
    width: 70%;
    display: flex;
    margin: 5rem auto;
}

.whirl::before {
    -webkit-animation: whirl 2s infinite linear;
    animation: whirl 2s infinite linear;
    background-color: var(--color-primary);
    border-radius: 100%;
    content: '';
    height: 30px;
    width: 30px;
}

@-webkit-keyframes scaling-dots {
    0%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40%, 60% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scaling-dots {
    0%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40%, 60% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.container-scaling {
    position: relative;
    align-items: center;
    width: 100%;
    justify-content: center;
    overflow: hidden;
    display: flex;
}

.scaling-dots {
    display: grid;
    height: 10px;
    grid-gap: 5px;
    grid-template-columns: repeat(5, 10px);
}

.scaling-dots div {
    -webkit-animation: scaling-dots 1s infinite ease backwards;
    animation: scaling-dots 1s infinite ease backwards;
    background: white;
    border-radius: 100%;
    height: 10px;
    width: 10px;
}

.scaling-dots div:nth-child(1) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.scaling-dots div:nth-child(2) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.scaling-dots div:nth-child(3) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.scaling-dots div:nth-child(4) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.scaling-dots div:nth-child(5) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
