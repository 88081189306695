@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import url("./assets/font/stylesheet.css");

:root {
  --color-primary: #FFAB00;
  --color-primary-dark: #F4AD3D;
  --color-primary-light-1: #feebc2;
  --color-primary-light-2: #ffc41b;
  --color-grey-light-1: #F7F7F7;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-blue: #2445D4;
  --color-blue-1: #cdd5f7;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;

  --color-red-1: #fdf2f2;
  --color-red-2: #e83b3b;

  --color-green-1: #e8fff8;
  --color-green: #018158;

  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, .06);
}

$bp-largest: 81.5em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; //900px
$bp-small: 43.75em; // 700px
$bp-smaller: 31.25em; // 500px

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 50.5%;
  scroll-behavior: smooth;

  @media only screen and (max-width: $bp-largest) {
    font-size: 47%;
  }

}

body {
  font-family: 'Soleto', sans-serif;
  background-color: var(--color-grey-light-1);
  font-weight: 400;
  line-height: 1.6;
}


.recharts-pie-labels > .recharts-layer {
  background-color: var(--color-grey-light-4) !important;
}

@media screen and (min-width: 700px) {
  .App {
    position: relative;
  }

  .main-content {
    position: relative;
    padding-left: 21.2rem;
    max-width: 1920px;
    margin: 0 auto;
  }

}


.carousel-custom-root {
  min-width: 0 !important;
}

.pie_legend {
  display: grid;
  align-items: center;
  grid-template-columns: 1.2rem 1fr 1fr;
  grid-gap: 0.5rem;
  font-size: 1.28rem !important;

  div {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    display: inline-block;
  }

  span:last-child {
    font-weight: 500;
  }
}

.ent_per_chart {
  margin-left: -4rem !important;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.tabs {
  display: block;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 2rem 0 1rem 0;
  overflow: hidden;
}

.tabs [class^="tab"] label,
.tabs [class*=" tab"] label {
  color: #707070;
  letter-spacing: 0.33px;
  cursor: pointer;
  display: block;
  font-size: 1.44rem;
  padding: 2rem 0;
  text-align: center;
}

.tabs [class^="tab"] [type="radio"],
.tabs [class*=" tab"] [type="radio"] {
  border-bottom: 1px solid #707070;;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-bottom: 2px;
}

.tabs [class^="tab"] [type="radio"]:hover, .tabs [class^="tab"] [type="radio"]:focus,
.tabs [class*=" tab"] [type="radio"]:hover,
.tabs [class*=" tab"] [type="radio"]:focus {
  border-bottom: 1px solid #FFAB00;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tabs [class^="tab"] [type="radio"]:checked,
.tabs [class*=" tab"] [type="radio"]:checked {
  border-bottom: 3px solid #FFAB00;
  border-radius: 15px;
  color: #FFAB00;
  padding-bottom: 0;
}

.tabs [class^="tab"] [type="radio"]:checked + div,
.tabs [class*=" tab"] [type="radio"]:checked + div {
  opacity: 1;
}

.tabs [class^="tab"] [type="radio"] + div,
.tabs [class*=" tab"] [type="radio"] + div {
  display: block;
  opacity: 0;
  padding: 2rem 0;
  width: 90%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tabs .tab-2 {
  flex: 1 1 0px
}

.tabs .tab-2 [type="radio"] + div {
  width: 200%;
  margin-left: 200%;
}

.tabs .tab-2 [type="radio"]:checked + div {
  margin-left: 0;
}

.tabs .tab-2:last-child [type="radio"] + div {
  margin-left: 100%;
}

.tabs .tab-2:last-child [type="radio"]:checked + div {
  margin-left: -100%;
}

.label__active {
  color: var(--color-primary) !important;
  font-weight: 500;
}

abbr {
  text-decoration: none;
}

.ui-datepicker {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 -2px 10px 3px #dddddd;
  padding: 1rem;


  &-header {
    display: flex;
    column-gap: 1.5rem;
    font-size: 1.44rem;
    align-items: center;

    & > * {
      cursor: pointer;
    }
  }

  &-next {
    order: 2;
    font-size: 1.2rem;
  }

  &-prev {
    font-size: 1.2rem;
  }

  &-title {
    font-weight: 500;
  }

  &-calender {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.Toastify__toast--error {
  font-size: 1.5rem;
}

.Toastify__toast--success {
  font-size: 1.5rem;
}

.ReactModal__Content {
  @media screen and (max-width: 768px) {
    width: 90% !important;
    height: 75vh;
  }
}