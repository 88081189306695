.signin {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  overflow: hidden !important;

  @media screen and (max-width: 768px) {
    display: block;
  }

  .hero {
    padding: 1.6rem 2rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media screen and (max-width: 768px) {
      display: none;
    }

    img {
      height: auto;
      width: 25rem;
    }

    .container {
      padding: 2rem;
      border: 2px solid var(--color-primary);
      border-radius: 10px;
    }

    &__capsule {
      color: white;
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 20px;
      padding: 2rem 2.6rem;
      margin: 0 0 2rem;
      width: 85%;

      h3 {
        font-weight: normal;
        font-size: 3.2rem;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }

      button {
        outline: none;
        border: 1px solid white;
        border-radius: 5px;
        background-color: transparent;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.4rem;
        letter-spacing: 1px;
        padding: 2rem 6rem;
        cursor: pointer;
        color: white;
        margin-bottom: 2rem;
      }
    }
  }

  .main {
    background-color: #fff;
    padding-top: 6rem;
    height: 100vh;
    position: relative;

    .logo {
      display: none;
    }

    .option {
      margin-top: 1.6rem;
      text-align: center;
      font-size: 1.6rem;

      span {
        text-decoration: underline;
        cursor: not-allowed;
        font-weight: 500;
        color: var(--color-blue);
      }
    }

    @media screen and (max-width: 768px) {
      .logo {
        display: block;
        height: auto;
        width: 25rem;
        position: absolute;
        top: 1rem;
      }
    }

    & > * {
      width: 80%;
      margin: 0 auto;
    }

    h2 {
      font-weight: bold;
      font-size: 4.16rem;
      margin: 3rem auto;
    }

    form {
      & > * {
        width: 100%;
      }

    }

    .welcome {
      letter-spacing: 0.38px;
      color: #707070;
      font-size: 1.92rem;
      margin: 2rem auto;
    }

    .text_input {
      padding: 1.52rem 1.28rem;
      outline: none;
      background-color: inherit;
      border-radius: 5px;
      font-size: 1.6rem;
      border: 1px solid #707070;
      display: block;
      position: relative;
    }

    .input_container {
      margin-bottom: 2.5rem;
      position: relative;
      width: 100%;

      input {
        width: 100%;
      }

      small {
        color: red;
        font-size: 1.4rem;
      }
    }

    .remember {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
      margin-bottom: 3rem;
      font-size: 1.44rem;

      div {
        display: flex;
        align-items: center;
        column-gap: 0.6rem;

        p {
          text-decoration: none;
          color: #000;
        }
      }

      a {
        text-decoration: underline;
        color: var(--color-primary);
      }
    }


    .sign_in_btn {
      color: white;
      background-color: #2445D4;
      border-radius: 5px;
      text-transform: capitalize;
      font-weight: bold;
      font-size: 1.76rem;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 1.6rem;
      letter-spacing: 0.6px;
      height: 6rem;

      &:disabled {
        cursor: default !important;
        background-color: var(--color-grey-dark-3) !important;
      }

      svg {
        fill: white;
        width: 2.16rem;
        height: 1.92rem;
      }


      .btn_content {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 0.6rem;
        height: 25px;
      }
    }

  }
}

.policies {
  letter-spacing: 0.43px;
  font-weight: 500;
  font-size: 1.44rem;
  text-align: center;
  margin-bottom: 1.92rem !important;

  a {
    color: #2445D4;
    text-decoration: none;
  }
}

.names {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.96rem;
}

.errors {
  color: red;
  font-weight: 500;
  font-size: 1rem;
  transition: all .2s ease-in-out;
}
